interface MessageBlockProps {
    text: string | TrustedHTML | undefined
    self: boolean | undefined
    time: string | number | undefined
    sender: string | undefined
}

const MessageBlock = ({ text, self, time, sender }: MessageBlockProps) => {
    const extraClasses = self ? 'float-end bg-tibi-lightGreen right-top self' : 'bg-white left-top border other'

    return (
        <div className={'message-block relative rounded-md px-4 py-2 w-2/3 mb-4 mx-6 text-tibi-text ' + extraClasses}>
            {!self && <span className='font-semibold text-tibi-mainHeading text-xs mb-2'>{sender}</span>}
            <div className='text-sm w-11/12' dangerouslySetInnerHTML={{ __html: text ? text : '' }}></div>
            <div className='text-xs absolute right-2 bottom-2 opacity-70'>{time}</div>
        </div>
    )
}

export default MessageBlock
